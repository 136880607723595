@import "mixin";

$black:#222;
$white:#fff;
$base:#f9f9f2;
$base2:rgb(198, 186, 120);
$gray:#8d9ead;
$blue:#171632;
$cyan:#50abb6;
$blue2:#26253d;


$font: 'Noto Serif JP','Helvetica Neue','Helvetica','Arial','Hiragino Sans','ヒラギノ角ゴシック',YuGothic,'Yu Gothic','メイリオ',Meiryo,'ＭＳ Ｐゴシック','MS PGothic';
$en: 'Karla', 'Noto Sans JP','Helvetica Neue','Helvetica','Arial','Hiragino Sans','ヒラギノ角ゴシック',YuGothic,'Yu Gothic','メイリオ', Meiryo,'ＭＳ Ｐゴシック','MS PGothic';
$fgo: 'Noto Sans JP','Helvetica Neue','Helvetica','Arial','Hiragino Sans','ヒラギノ角ゴシック',YuGothic,'Yu Gothic','メイリオ', Meiryo,'ＭＳ Ｐゴシック','MS PGothic';

$time: 1s;
$ease: cubic-bezier(0.55, 0.05, 0.22, 0.99);

// mからスマホです！
$breakpoints: ('xxl': 'screen and (max-width: 1632px)',
	'xl': 'screen and (max-width: 1232px)',
	'l' : 'screen and (max-width: 1023px)',
	'ml' : 'screen and (max-width: 932px)',
	'm' : 'screen and (max-width: 880px)',
	's': 'screen and (max-width: 640px)',
	'xs': 'screen and (max-width: 640px)',
	'xxs' : 'screen and (max-width: 407px)',
);
// @include mq(s){}
